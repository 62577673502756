.app__specialMenu{
    flex-direction: column;
    background: var(--color-black);
}

.app__specialMenu-title{
    margin-bottom: 2rem;
    text-align: center;
}
.app__specialMenu-menu{
    width: 100%;
    margin: 2rem 0;
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: flex-start;
}

.app__specialMenu-menu_heading{
    font-family: var(--font-base);
    font-size: 45px;
    font-weight: 600;
    line-height: 58px;
    letter-spacing: 0.04rem;
    color: var(--color-white);
}

.app__specialMenu-menu_wine, .app__specialMenu-menu_dishes{
    flex: 1;
    flex-direction: column;
    width: 100%;
}

.app__specialMenu-menu_img{
    width: 410px;
    margin: 0 2rem;
}
.app__specialMenu-menu_img img{
  width: 100%;
  height: auto;
}

.app__specialMenu_menu_items{
    display: flex;
    flex-direction: column;
    margin:  2rem 0;
    width: 100%;
}

@media screen and (max-width: 2000px) {
    .app__specialMenu-menu_img{
        width: 650px;
    }
    .app__specialMenu-menu_img img{
        height: 920px;
    }
}
@media screen and (max-width: 1150px) {
    .app__specialMenu-menu{
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .app__specialMenu-menu_img{
        margin: 3rem 0;
    } 

}
@media screen and (max-width: 650px) {
    .app__specialMenu-menu_img{
        width: 100%;
    }
    .app__specialMenu-menu_heading{
        font-size: 30px;
        line-height: 38px;
    }
}