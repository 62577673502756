.app__aboutus{
    position: relative;
}

.app__aboutus-overlay{
    position: absolute;
    inset: 0;
}

.app__aboutus-overlay img{
    width: 700px;
    height: 700px;
    z-index: 0;
}

.app__aboutus-content{
    position: relative;
    z-index: 2;
}

.app__aboutus-content_about{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: right;
}
.app__aboutus-content_watch{
    margin: 1rem 4rem;
}

.app__aboutus-content_watch img{
    height:800px;
    width: 250px;
    opacity: 0.16;
    z-index: -10;
}


.app__aboutus-content_history{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    position: relative;
}

.app__aboutus-content_history p,
.app__aboutus-content_about p{
    margin: 2rem 0;
    color: rgb(235, 234, 234);
}


.app__aboutus-content_history button{
    
    position: absolute;
    right: 0;
    bottom: 0;
}









@media screen and (max-width: 2000px){
    .app__aboutus-content_watch img{
        height: 900px;
    }
    .app__aboutus-content_history p
    {
    margin: 4rem 0;
    color: rgb(235, 234, 234);

    }

    .app__aboutus-content_about p{
        text-align: left;
    }

}

@media screen and (max-width: 900px){
    .app__aboutus-content{
        flex-direction: column;
    }

    .app__aboutus-content_watch{
        margin: 1rem 0;
    }
    

}
@media screen and (max-width: 650px){
    .app__aboutus-overlay img{
        width: 100%;
        height: 320px;
    }
}



